import Vue from 'vue';

const state = {
  lista: [],
};

const actions = {
  fetchLista:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.get('estados-civiles/lista',data).then(
        response =>{
          commit('setLista',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
};

const getters = {
  getTipoDocumentos: (state) => (id) =>{
    return state.lista.find(element =>{
      return element.id == id
    })
  },
};

const mutations = {
  setLista: (state, lista) => {
    state.lista = lista;
  },
  restart: (state) => {
    state.lista = []
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
