<template>
  <div class="container mt-3">

    <div v-if="user.data.debe_actualizar" class="actualizacion  m-auto">
    	
        <h4 class="text-center">Usted ingresa por primera vez a nuestro sistema</h4>
        <h4 class="text-center">Es necesario actualizar alguna información:</h4>
        <div class="actualizar_curso p-3 mt-3">
          <div class="informar text-center" v-if="cursoActual">
            <div class=" text-center mt-3">
              <h4 class="text-center">Usted actualmente se encuentra en el nivel:</h4>
            </div>
            <div class=" text-center mt-3">
                <span>{{ cursoActual.nombre }}</span>
            </div>
            <div class=" text-center actions mt-3">
              <button class="btn btn-sm btn-primary m-3" @click="esCorrecto">Es correcto</button>
              <button class="btn btn-sm btn-danger m-3" @click="noEsCorrecto">No es correcto</button>
            </div>
          </div>

          <div v-if="puede_seleccionar_curso" class="form-group m-3">
            <div class="">Seleccione el curso correcto:</div>
            <div class="m-3">
              <select v-model="curso_seleccionado" class="form-control">
                <option disabled value="">Please select one</option>
                <option v-for="cursoOption in cursos" :value="cursoOption">{{ cursoOption.nombre }}</option>
              </select>
            </div>
            <button class="btn btn-block btn-danger" @click="guardar">Guardar</button>
          </div>
        </div>
        <div v-if="mensaje" class="mt-1 alert alert-success" >{{ mensaje }}</div>
      
    </div>
    <div v-else class="actualizacion  m-auto">
      <h3>No hay actualizaciones pendientes</h3>
      <div v-if="mensaje" class="mt-1 alert alert-success" >{{ mensaje }}</div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';

  export default {
    name: 'ActualizacionForm',
    data () {
      return {
        curso_seleccionado: null,
        cursos: null,
        puede_seleccionar_curso: false,
        mensaje : null,
        
      }
    },
    
    mounted () {
      Vue.http.post('cursos').then(
        response =>{
          this.cursos = response.data
        }
      )
      .catch(error=>{
        this.mensaje = error.data.error.message
        setTimeout(() => {this.mensaje = null},3000)
        console.log(error)
      })
      .finally(()=>{
        
      })
      
    },
    methods:{
      ...mapActions({
        actualizarDatos: 'auth/profile',
      }),
      ...mapMutations({

      }),
      esCorrecto(){
        this.curso_seleccionado = this.cursoActual
        this.guardar()
      },
      noEsCorrecto(){
        this.puede_seleccionar_curso = true
        this.curso_seleccionado = this.cursoActual
      },
      guardar(){
        this.actualizarDatos({
          curso_actual_id:this.curso_seleccionado.id,
        })
        .then(apiResponse=>{
          this.mensaje = apiResponse.data.message
          setTimeout(() => {this.mensaje = null},3000)
        })
        .catch(apiResponse=>{
          console.log(apiResponse)
        })
        .finally(apiResponse=>{
          this.mensaje = 'Gracias por la información.'
          setTimeout(() => {
            this.$router.push('/');
          },3000)
        })
      }
    },
    computed:{
      ...mapState({
        user: state => state.auth.user, 
      }),
      ...mapGetters({
        
      }),
      cursoActualId(){
        return this.user.data.curso_actual_id
      },
      cursoActual(){
        if(this.cursoActualId){
          if(this.cursos){
            return this.cursos.find(element=>{
              return element.id == this.cursoActualId
            })       
          }
        }
        return null
      }
      
    }
  }
</script>

<style scope>
  .programar_wrapper .form-container{
      
  }
	.programar_wrapper{
    
	}
  .actualizacion{
    max-width: 600px
  }
  .actualizar_curso{
    border: 1px solid #bbb;    
  }
</style>

