<template>
  <header>
    <b-navbar toggleable="md" type="dark"  class="pt-0 pb-0 bg-blue">
      <b-navbar-toggle v-if="isLogged" target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand to="/"><img style="width:100px" src="../assets/logo_small_white.png"></b-navbar-brand>
      <b-collapse is-nav id="nav_collapse">
        
        <b-navbar-nav>
          <router-link v-if="isLogged" class="nav-link" to="/" >Mis Clases</router-link>
          <router-link v-if="isLogged" class="nav-link" to="/programar" >Programar</router-link>
          <router-link v-if="isLogged" class="nav-link" to="/registro_academico" >RegistroAcademico</router-link>
          <router-link v-if="isLogged" class="nav-link" to="/notificaciones" >Notificaciones</router-link>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <router-link v-if="isLogged" class="nav-link" to="/profile" >Mi Cuenta</router-link>
          <a href="#" v-if="isLogged" class="nav-link"  @click.prevent="processLogout()">Salir</a>
          
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>

    
  </header>
</template>

<script>
  import {mapState, mapMutations, mapActions} from 'vuex';
  
  export default {
    components: {},
    name: 'navigation',
    methods: {
      ...mapActions({
        logout: 'auth/logout'
      }),
      ...mapMutations({
        clear : 'academico/clear'
      }),
      processLogout () {
        this.clear()
        this.logout().then(
          this.$router.push('/login')
        )
      },
      
    },
    computed: mapState({
      isLogged: state => state.auth.logged
    }),

  }
</script>
