<template>
  <div class="perfil_wrapper mt-3">

    <div class="container col-lg-6 m-auto">
      <h1 class="text-center text-muted">Mi Cuenta</h1>
      <hr>
      <div class="row">
        <div class="col-3"><strong>Nombres:</strong></div><div class="col-9">{{ user.data.primer_nombre }} {{ user.data.segundo_nombre }}</div>
      </div>
      <div class="row">
        <div class="col-3"><strong>Apellidos:</strong></div><div class="col-9">{{ user.data.primer_apellido }} {{ user.data.segundo_apellido }}</div>
      </div>
      <div class="row">
        <div class="col-3"><strong>Correo:</strong></div><div class="col-9">{{ user.data.email }}</div>
      </div>
      <div class="row">
        <div class="col-3"><strong>Documento:</strong></div><div class="col-9">{{ user.data.numero_documento }}</div>
      </div>
      <hr>
      <div v-if="cursoActual">
        <div  class="row">
          <div class="col-3"><strong>Curso Actual:</strong></div><div class="col-9">{{ cursoActual.nombre }}</div>
        </div>
        <div  class="row" v-if="user && user.data && user.data.vigencia">
          <div class="col-3"><strong>Vigencia:</strong></div><div class="col-9">{{ user.data.vigencia }}</div>
        </div>
      </div>
      <hr>

      <div v-if="sedes.length>0 && sedeOriginal && sedeOriginal.nombre">
        <div  class="row">
          <div class="col-3"><strong>Sede Original:</strong></div>
          <div class="col-9">{{ sedeOriginal.nombre }}</div>
        </div>
      </div>
<!-- //YA NO ES NECESARIO MOSTRAR AQUÍ LA SEDE ACTUAL
      <div v-if="sedeActual">
        <div  class="row">
          <div class="col-3"><strong>Sede Actual:</strong></div><div class="col-9">{{ sedeActual.nombre }}</div>
        </div>
      </div>
    -->
      <hr>
      <b-btn  :variant="show_notification?'success':'secondary'" class="mx-1" size="sm" @click="setShowNotification(!show_notification)">
     Mostrar Notificaciones</b-btn>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

  export default {
    name: 'perfil',
    
    mounted () {
      if(!this.cursoActual){
        this.fetchCursoActual()
      }
      if(!this.sedeActual){
        this.fetchSedeActual()
      }
      if(!this.sedes || (this.sedes && this.sedes.length==0)){
        this.fetchSedes()
      }
    },
    methods:{
      ...mapActions({
        fetchSedes: 'sedes/fetchLista',
        fetchCursoActual: 'academico/fetchCursoActual',
        fetchSedeActual: 'academico/fetchSedeActual',
      }),
      ...mapMutations({
        setShowNotification: 'setShowNotification'
      }),
      
        
    },
    computed:{
      ...mapState({
        user: state => state.auth.user, 
        sedeActual: state => state.academico.sedeActual, 
        cursoActual: state => state.academico.cursoActual,
        show_notification: state => state.show_notification,
        sedes: state  => state.sedes.lista,
      }),
      ...mapGetters({
        getSede: 'sedes/getSede',
      }),
      sedeOriginal(){
        return this.getSede(this.user.data.sede_original_id)
      }, 
      
    }
    
  }
</script>

<style scope>

	.perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
	}
</style>