import Vue from 'vue';

const state = {
  banners: [],
  bannerDialogShowed: false
};

const actions = {
  fetchBanners:({commit}) => {
     commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('bannersFull').then(
        response =>{
          commit('setBanners',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
};

const getters = {
  
};

const mutations = {
  setBanners: (state, banners) => {
    state.banners = banners;
  },
  showDialog: (state) => {
    state.bannerDialogShowed = true;
  },
  hideDialog: (state) => {
    state.bannerDialogShowed = false;
  }

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
