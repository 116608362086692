<template>
  <div class="container container-login mt-3">
    <div class="row">
      <div class="justify-content-center col-lg-6 m-auto">
        <h1 class="text-center text-muted">
          <span>Ingresar</span>
        </h1>
        <hr />
        <user-form
          :isLogin="true"
          :user="user"
          btnText="Enviar"
          v-on:processUserForm="processLogin($event)"
        ></user-form>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapMutations} from 'vuex';
  import UserForm from "./UserForm";
  export default {
    components: {UserForm},
    name: 'login',
    data () {
      return {
        user: {
          email: '',
          password: '',
          manetner_sesion:0
        },
      }
    },
    methods: {
      ...mapActions({
        login: 'auth/login',
        verificarAceptacionTerminos: 'academico/verificarAceptacionTerminos',
        verificarMensajes: 'auth/verificarMensajes',
        fetchTipos: 'academico/fetchTipos', //
        fetchSedeActual:'academico/fetchSedeActual', //
        fetchBanners: 'banner/fetchBanners', //
        fetchCursoActual: 'academico/fetchCursoActual', //
        fetchSedes: 'sedes/fetchLista',
      }),
      ...mapMutations({
        bannerShowDialog : 'banner/showDialog'
      }),
      processLogin (user) {
        this.login({
          email: user.email,
          password: user.password,
          manetner_sesion: user.manetner_sesion
        }).then(
          user => {
            this.$router.push('/');
            this.verificarAceptacionTerminos()
            this.verificarMensajes()
            this.fetchTipos()
            this.fetchSedes()
            this.fetchCursoActual()
            this.fetchSedeActual()
            this.fetchBanners()
            .then((result)=>{
              if(result && result.length>0){
                this.bannerShowDialog()
              }
            })        

          },
        )
      }
    }
  }
</script>
