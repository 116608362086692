import Vue from 'vue';

const state = {
  
  tipos: [],
  clases: [],
  cursoActual: null,
  sedeActual: null,
  siguienteTema: null,
  horariosDisponibles : null,
  ultima_actualizacion : null,
  ultima_actualizacion_horarios : null,
  debe_aceptar_terminos : false,
  terminos_y_condiciones : null,
  registro_academico_actual: [],
  finalizar_nivel_resultado: null,
  solicitar_avance_resultado: null
};

const actions = {
  fetchClases:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clases',payload).then(
        response =>{
          commit('setClases',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchTipos:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('ver_tipos').then(
        response =>{
          commit('setTipos',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchCursoActual:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('curso_actual').then(
        response =>{
          commit('setCursoActual',response.data);
          commit('stopProcessing', null, { root: true });
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  fetchSedeActual:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('sede').then(
        response =>{
          commit('setSedeActual',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  fetchHorarios:({commit},temaId) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('horarios',{tema_id:temaId}).then(
        response =>{
          commit('setHorariosDisponibles',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setHorariosDisponibles',null);
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchDisponibilidad:({commit}, showError = true) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('ver_disponibilidad').then(
        response =>{
          commit('setSiguienteTema',response.data.tema);
          commit('setHorariosDisponibles',response.data.horarios);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setSiguienteTema',null);
        commit('setHorariosDisponibles',null);
        if (showError) {
          commit('setError', error, { root: true });
        }
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  agendar:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('agendar',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  cancelar:({commit,dispatch},agendamientoId) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('cancelar',{
          agendamiento_id:agendamientoId,
        }).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
        dispatch('fetchClases')
      })
    });
  },
  aceptar:({commit,dispatch},agendamientoId) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('aceptar',{
          agendamiento_id:agendamientoId,
        }).then(
        response =>{
          dispatch('fetchClases')
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  verificarAceptacionTerminos:({commit,dispatch}) => {
    commit('startProcessing', null, { root: true });
    
      Vue.http.post('verificar_terminos').then(
        response =>{
          if(response.data.codigo){
            if(response.data.codigo=='requerido'){
              commit('setDebeAceptarTerminos',true);
              commit('setTerminosYCondiciones',response.data.texto);
            }else if(response.data.codigo=='aceptado'){
              commit('setDebeAceptarTerminos',false);
              commit('setTerminosYCondiciones',response.data.texto);
            }else if(response.data.codigo=='desactivado'){
              commit('setDebeAceptarTerminos',false);
            }
          }else{
            commit('setDebeAceptarTerminos',false);
          }
    
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
    
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    
  },

  aceptarTerminos:({commit,dispatch}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('aceptar_terminos').then(
        response =>{
          if(response.data.codigo){
            if(response.data.codigo=='ok'){
              commit('setDebeAceptarTerminos',false);
              if(response.data.mensaje){
                commit('setWarning', response.data.mensaje, { root: true });  
              }
            }
          }
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  fetchRegistroAcademicoActual:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('registro_academico_actual').then(
        response =>{
          commit('setRegistroAcademicoActual',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
  fetchSolicitarAvance:({commit}, data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('solicitar-avance', data).then(
        response =>{
          commit('setFinalizarNivel',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  fetchCursoFinalizado:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.get('curso_finalizado').then(
        response =>{
          commit('setCursoFinalizado',response.data.dato);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  avanzarNivel:({commit}, data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('avanzar_nivel', data).then(
        response =>{
          commit('setSolicitarAvance',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  fetchEncuestas:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.get('encuestas').then(
        response =>{
          commit('setEncuesta',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  saveEncuesta:({commit}, data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('guardar_encuesta', data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  }
  
  
};

const getters = {
  formattedClases: state => {
    let clases = [];
      if(state.clases && state.clases.length) {
        state.clases.map(agendamiento => {
          let puedecancelar = false;
          let infoclase = null
          let texto_estado = agendamiento.estado

          if(agendamiento.estado=='confirmado'){
            texto_estado = 'salon asignado'
            if(agendamiento.clase.salon.numero){
              infoclase = agendamiento.clase.salon.numero+' '+agendamiento.clase.salon.nombre
            }else{
              infoclase = agendamiento.clase.salon.nombre
            }
            
          }else if(agendamiento.estado=='solicitado'){
            puedecancelar = true;              
          }
          
          let texto_tipo_clase = 'Sin definir'

          clases.push({
              id: agendamiento.id,
              tipo_clase: texto_tipo_clase,
              tema: agendamiento.tema?agendamiento.tema.nombre:'Sin definir',
              fecha: agendamiento.fecha,
              hora: agendamiento.hora,
              estado: texto_estado.toUpperCase(),
              puedecancelar:puedecancelar,
              infoclase:infoclase
          })   
        });
      }
      return clases;
  },
  clasesPendientes: state => {  
     let pendientes =  state.clases.filter(agendamiento =>{
        return agendamiento.estado != 'cancelado'
     })
     return pendientes 
  },
  getTipo: (state) => (codigo) => {
      return  state.tipos.find((element)=>{
        return element.id == codigo
      })
  },
};

const mutations = {
  setDebeAceptarTerminos: (state, data) => {
    state.debe_aceptar_terminos = data;
  },
  setTerminosYCondiciones: (state, data) => {
    state.terminos_y_condiciones = data;
  },
  setTipos: (state, data) => {
    state.tipos = data;
  },
  setClases: (state, data) => {
    state.clases = data;
    state.ultima_actualizacion = new Date()
  },
  setCursoActual: (state, data) => {
    state.cursoActual = data;
  }, 
  setSedeActual: (state, data) => {
    state.sedeActual = data;
  },
  setSiguienteTema: (state, tema) => {
    state.siguienteTema = tema;
  },
  setHorariosDisponibles: (state, horarios) => {
    state.horariosDisponibles = horarios;
    state.ultima_actualizacion_horarios = new Date()
  },
  setRegistroAcademicoActual: (state, data) => {
    state.registro_academico_actual = data;
  },
  setFinalizarNivel: (state, data) => {
    state.finalizar_nivel_resultado = data;
  },
  setCursoFinalizado:(state, data) => {
    state.curso_finalizado = data;
  },
  setSolicitarAvance: (state, data) => {
    state.avance_nivel_resultado = data;
  },
  setEncuesta: (state, data) => {
    state.encuestas = data;
  },
  
  clear: (state) => {
    state.clases = []
    state.cursoActual = null
    state.sedeActual = null
    state.temasDisponibles = []
    state.horariosDisponibles = null
    state.registro_academico_actual = [],
    state.finalizar_nivel_resultado = null,
    state.curso_finalizado = null,
    state.avance_nivel_resultado = null,
    state.encuestas = null
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
