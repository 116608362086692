import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Auth/Login';
import Programaciones from '@/components/Programaciones';
import RegistroAcademico from '@/components/RegistroAcademico';
import Programar from '@/components/Programar';
import Profile from '@/components/Profile';
import ActualizacionForm from '@/components/Auth/ActualizacionForm';
import Notificaciones from '@/components/Notificaciones/Banners';
import SolicitarAvance from '@/components/SolicitarAvance';
import FinalizarNivel from '@/components/FinalizarNivel';

import store from './store'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/index.html',
      name: 'home_index',
      component: Programaciones,
      meta: { Auth: true, title: 'Mis Clases' },
    },
    {
      path: '/',
      name: 'home',
      component: Programaciones,
      meta: { Auth: true, title: 'Mis Clases' },
    },
    {
      path: '/programar',
      name: 'programar',
      component: Programar,
      meta: { Auth: true, title: 'Programar' },
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { Auth: true, title: 'Perfil'},
    },
    {
      path: '/registro_academico',
      name: 'registro_academico',
      component: RegistroAcademico,
      meta: { Auth: true, title: 'Registro Academico'},
    },
    {
      path: '/actualizar_datos',
      name: 'actualizar_datos',
      component: ActualizacionForm,
      meta: { Auth: true, title: 'Perfil', Updating : true },
    },
    {
      path: '/notificaciones',
      name: 'notificaciones',
      component: Notificaciones,
      meta: { Auth: true, title: 'Notificaciones', Updating : true },
    },
    {
      path: '/finalizar-nivel',
      name: 'finalizar-nivel',
      component: FinalizarNivel,
      meta: { Auth: true, title: 'Finalizar Nivel', Updating : true },
    },
    {
      path: '/avanzar-nivel',
      name: 'avanzar-nivel',
      component: SolicitarAvance,
      meta: { Auth: true, title: 'Avance de Nivel', Updating : true },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { Auth: false, title: 'Programador de Clases de American School Way' },
      beforeEnter: (to, from, next) => {
        if(store.state.auth.logged) {
          next({ path: '/' });
        } else {
          next();
        }
      }
    }
  ]
})


//.configurar el router
//para cada cambio de ruta

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.Auth && !store.state.auth.logged) {
    next({path: '/login'});
  }else {
    if (store.state.auth.logged) {
      store.commit('auth/setUser');
    }
    if (store.state.auth.logged && store.state.auth.user.data.debe_actualizar && !to.meta.Updating) {
      next({path: '/actualizar_datos'});
    }else{
      next();  
    }

  }
});

//.para cada cambio de ruta


export default router;