const utils = {
  getTextoHora:(value)=>{
  	switch(value){
  		case '06:00:00': return '6:00 am'; break; 
      case '07:30:00': return '7:30 am'; break; 
      case '08:00:00': return '8:00 am'; break;
      case '09:00:00': return '9:00 am'; break; 
      case '09:30:00': return '9:30 am'; break;
      case '10:40:00': return '10:40 am'; break; 
      case '11:00:00': return '11:00 am'; break;
      case '12:10:00': return '12:10 pm'; break; 
      case '13:00:00': return '1:00 pm'; break;
      case '13:40:00': return '1:40 pm'; break; 
      case '15:10:00': return '3:10 pm'; break; 
      case '16:50:00': return '4:50 pm'; break; 
      case '18:20:00': return '6:20 pm'; break; 
      case '19:50:00': return '7:50 pm'; break; 
  	}
  	return value
  },
  eliminarCaracteres(str) {
    if (str) {
      const sustitutions = {
        àáâãäå: "a",
        ÀÁÂÃÄÅ: "A",
        èéêë: "e",
        ÈÉÊË: "E",
        ìíîï: "i",
        ÌÍÎÏ: "I",
        òóôõö: "o",
        ÒÓÔÕÖ: "O",
        ùúûü: "u",
        ÙÚÛÜ: "U",
        ýÿ: "y",
        ÝŸ: "Y",
        ß: "ss",
        ñ: "n",
        Ñ: "N"
      };
      // Devuelve un valor si 'letter' esta incluido en la clave
      function getLetterReplacement(letter, replacements) {
        const findKey = Object.keys(replacements).reduce(
          (origin, item, index) => (item.includes(letter) ? item : origin),
          false
        );
        return findKey !== false ? replacements[findKey] : letter;
      }
      // Recorre letra por letra en busca de una sustitución
      ////////////////////////////////////////////////////////////////////////////////////////////
      // str = str.split("").map((letter) => getLetterReplacement(letter, sustitutions)).join("");
      return str.replace(/[^a-zA-Z0-9À-ÿ+()¡!¿?., -]+/g,'');
    }
    else return "";
  },
  LETTER_REGEX: /^[a-zA-Z0-9À-ÿ+()¡!¿?., -]+$/
};

export default utils;