import Vue from 'vue'
import App from './App.vue'
import config from './config'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//vue resource
import VueResource from 'vue-resource';
Vue.use(VueResource);
Vue.http.options.root = config.apiUrl;

Vue.http.interceptors.push((request, next) => {
  request.headers.set('Authorization', `Bearer ${window.localStorage.getItem('_token')}`);
  next();
});
//.vue resource

//vue native-notification
import VueNativeNotification from 'vue-native-notification'
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})
//.vue native-notification

//vue cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
//.vue cookies

//blockui
import BlockUI from 'vue-blockui';
Vue.use(BlockUI);
//.blockui

//vue bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//.vue bootstrap

//vee-validate
import VeeValidate, {Validator} from 'vee-validate';
import validatorEs from '@/validator/es';
//import validatorEn from '@/validator/en';
Validator.localize('es', validatorEs);
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
//.vee-validate

//vue-tables-2
import {ClientTable} from 'vue-tables-2';
Vue.use(ClientTable, {}, false, 'bootstrap4', 'default');
//.vue-tables-2

import VueMoment from 'vue-moment'
require('moment/locale/es')
Vue.use(VueMoment)


import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
// Required dependency of bootstrap css/scss files
// import 'bootstrap/css/bootstrap.css';
// Global registration
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

new Vue({
	store,
	router,
	render: h => h(App)
}).$mount('#app')
