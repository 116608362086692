var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"perfil_wrapper mt-3"},[_c('div',{staticClass:"container col-lg-6 m-auto"},[_c('h1',{staticClass:"text-center text-muted"},[_vm._v("Mi Cuenta")]),_c('hr'),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.user.data.primer_nombre)+" "+_vm._s(_vm.user.data.segundo_nombre))])]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.user.data.primer_apellido)+" "+_vm._s(_vm.user.data.segundo_apellido))])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.user.data.email))])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.user.data.numero_documento))])]),_c('hr'),(_vm.cursoActual)?_c('div',[_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.cursoActual.nombre))])]),(_vm.user && _vm.user.data && _vm.user.data.vigencia)?_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.user.data.vigencia))])]):_vm._e()]):_vm._e(),_c('hr'),(_vm.sedes.length>0 && _vm.sedeOriginal && _vm.sedeOriginal.nombre)?_c('div',[_c('div',{staticClass:"row"},[_vm._m(6),_c('div',{staticClass:"col-9"},[_vm._v(_vm._s(_vm.sedeOriginal.nombre))])])]):_vm._e(),_c('hr'),_c('b-btn',{staticClass:"mx-1",attrs:{"variant":_vm.show_notification?'success':'secondary',"size":"sm"},on:{"click":function($event){return _vm.setShowNotification(!_vm.show_notification)}}},[_vm._v(" Mostrar Notificaciones")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Nombres:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Apellidos:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Correo:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Documento:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Curso Actual:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Vigencia:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('strong',[_vm._v("Sede Original:")])])
}]

export { render, staticRenderFns }