<template>
    <b-modal id="modal-encuesta" centered button-size="sm" no-close-on-esc no-close-on-backdrop>
        <template #modal-header="{ close }">
            <!-- @ok="procesarEncuesta" @cancel="hideModal" -->
            <!-- Emulate built in modal header close button action -->
            <h5></h5>
            <b-button size="sm" variant="outline-default" @click="procesarEncuesta()">
                Omitir
            </b-button>
        </template>
        
        <div v-if="paso == 1">
            <h5 class="text-center">Califica tu clase</h5>
            <p class="my-3 text-center">{{agendamiento.sede.nombre}} {{agendamiento.hora}} con el docente <b>{{agendamiento.clase.profesor.primer_nombre}} {{agendamiento.clase.profesor.primer_apellido}}</b> </p>
            <div class="row justify-content-md-center justify-content-center text-center">
                <div class="col-md-auto">
                    <b-form-group v-slot="{ ariaDescribedby }" class="bg-calificacion">
                        <b-form-radio-group
                            class="bg-transparent"
                          id="btn-radios-1"
                          v-model="encuesta.calificacion"
                          :aria-describedby="ariaDescribedby"
                          name="radios-btn-default"
                          button-variant="outline-link"
                          border-transparent
                          text-variant="white"
                          size="xs"
                          buttons>
                            <template v-for="(option, index) in options">   
                                <b-form-radio :value="option.value">
                                    <b-icon :icon="option.icon" :class="option.class"></b-icon>
                                </b-form-radio> 
                            </template>
    
                        </b-form-radio-group>
                    </b-form-group>
                </div>
            </div>
        </div>
        <div class="form-group" v-if="paso == 2">
            <label class="col-form-label">¿Quieres dejar una observación?</label>
            <b-form-textarea
                id="textarea"
                v-model="encuesta.comentarios" :maxLength="comentariosMax"
                placeholder="¿Quieres dejar una observación?"
                rows="3" max-rows="6" @keypress="isLetter($event)" @change="reviewTextChange($event)"
                  @paste.prevent>
            </b-form-textarea>
            <pre class="d-block text-right">{{ encuesta.comentarios.length }}</pre>
          </div>

          <template #modal-footer>
            <div class="w-100">
              <p class="float-left">
                <!-- Modal Footer Content -->
              </p>
              <b-button
                v-if="paso == 1"
                variant="primary"
                size="sm"
                class="float-right"
                @click="siguiente"
                :disabled="!encuesta.calificacion"
              >
                Siguiente
              </b-button>
              <b-button
                v-if="paso == 2"
                variant="primary"
                size="sm"
                class="float-right"
                @click="procesarEncuesta"
              >
                Enviar
              </b-button>
            </div>
          </template>
    </b-modal>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import utils from '@/modules/utils';

export default {
    name: 'Encuesta',
    data() {
        return {
            paso:1,
            sede_id:null,
            comentariosMax: 150,
            encuesta:{
                agendamiento_id:null,
                omitir:null,
                calificacion:null,
                comentarios:"",
            },
            options: [
                { icon: 'emoji-angry-fill', value: '1', class:"h1 red" },
                { icon: 'emoji-frown-fill', value: '2', class:"h1 yellow-orange" },
                { icon: 'emoji-neutral-fill', value: '3', class:"h1 yellow"},
                { icon: 'emoji-smile-fill', value: '4', class:"h1 green-yellow" },
                { icon: 'emoji-heart-eyes-fill', value: '5', class:"h1 green" }
            ],
            paso:1
        }
    },
    props:{
        agendamiento: Object,
        mostrar: Boolean
    },
    mounted () {
        this.showModal();
    },
    methods:{
        ...mapActions({
            saveEncuesta: 'academico/saveEncuesta',
        }),
        ...mapMutations({
            setShowNotification: 'setShowNotification'
        }),
        showModal() {
            this.encuesta.agendamiento_id = this.agendamiento.id;
            this.$bvModal.show('modal-encuesta')
        },
        hideModal() {
            this.$bvModal.hide('modal-encuesta')
            this.$emit('cerrar');
        },
        procesarEncuesta() {
            this.saveEncuesta(this.encuesta).then((result) => {
                // console.log(result);
            }).finally(() => {
                this.hideModal();
            })
        },
        siguiente() {
            this.paso = 2;
        },
        isLetter(e) {
          let char = String.fromCharCode(e.keyCode); // Get the character
          if(utils.LETTER_REGEX.test(char)) return true; // Match with regex 
          else e.preventDefault(); // If not match, don't add to input text
        },
        reviewText(event) {
            let char = String.fromCharCode(event.keyCode);
            if(!utils.LETTER_REGEX.test(char)) {
                this.encuesta.comentarios = utils.eliminarCaracteres(this.encuesta.comentarios);
            }
        },
        reviewTextChange(event) {
            if(!utils.LETTER_REGEX.test(this.encuesta.comentarios)) {
                this.encuesta.comentarios = utils.eliminarCaracteres(this.encuesta.comentarios);
            }
        }
    },
    computed:{
        ...mapState({
            user: state => state.auth.user,
        }),
        ...mapGetters({
        // getSede: 'sedes/getSede',
        }),
        validation() {
            return this.form.tipo_documento ? true : false
        },
        encuestaMostrar() {
            if (this.agendamiento && this.agendamiento.clase) {
                return true;
            }
            return false;
        }
    },
    watch: { 
        mostrar: function(newVal, oldVal) {
            if (newVal == true && this.encuestaMostrar) {
                this.showModal()
            }
        },
        // "encuesta.comentarios": function (old_val, new_val) {
        //     if(utils.LETTER_REGEX.test(new_val)) {
                
        //     }
        //     else {
                
        //     }
        // }
    }
}
</script>

<style scope>

    .perfil_wrapper .container{
        background-color: #fff;
        padding: 15px;
    }
    .red {
        color: #f9a19d !important; /* #dc3545 #e04b59 #e4606d #e77681, e6717c*/
    }
    .active .red{
        color: #dc3545 !important
    }
    .yellow-orange {
        color: #ffd6a2 !important; /* #FFAE42 #ffb95c #ffc475  #ffcf8f, ffc67a */
    }
    .active .yellow-orange {
        color: #FFAE42 !important;
    }
    .yellow {
        color: #fff29a !important; /* #ffe700 #ffe91a #ffec33 #ffee4d, ffee4c */
    }
    .active .yellow {
        color: #ffe700 !important;
    }
    .green-yellow {
        color: #d3ff9b !important; /* #95fe01 #a0fe1a #aafe34 #b5fe4d, b4fe4d */
    }
    .active .green-yellow {
        color: #95fe01 !important;
    }
    .green {
        color: #9cd49e !important; /*#28a745 #2dbc4e #34ce57 #48d368, 68c17c */
    }
    .active .green {
        color: #28a745 !important;
    }

    .bg-yellow-orange {
        background-color: #FFAE42 !important;
    }
    .bg-yellow {
        background-color: #ffe700 !important;
    }
    .bg-green-yellow {
        background-color: #95fe01 !important;
    }
    .active.focus{
        box-shadow: none !important;
    }
    .bg-calificacion  .btn .b-icon.bi { 
        font-size:200%;
    }
    .bg-calificacion .btn {
        padding: 0.0rem 0.50rem;
    }
    .bg-calificacion {
        margin-bottom: 0 !important
    }
</style>