import Vue from 'vue';

const state = {
  me: null,
};

const actions = {
    
  informacion: ({commit}, userInput) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.get('informacion')
        .then(response => {
            commit('setData',response.data);
            resolve(response.data);
        })
        .catch(error => {
          commit('setError', error, { root: true });
          reject(error);
        })
        .finally(() => {
          commit('stopProcessing', null, { root: true });
        })
    })
  },
  
};

const getters = {
  getData: (state) => () =>{
    return state.me
  },
};

const mutations = {
  setData: (state, me) => {
    state.me = me;
  },
  restart: (state) => {
    state.me = null
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
