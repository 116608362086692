<template>

  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Mis Clases</h1>
        
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-button class="nav-link" @click="actualizar" variant="link" >Actualizar</b-button>
          </b-navbar-nav>

      </div>
    </b-navbar>
    
    <div v-if="clases" class="container tabla mt-3">
      <span v-if="lastTime" class="text-muted" style="font-size: 8px;">Última actualización: {{ lastTime }} </span>
      <div v-if="clases.length>0">
        <ProgramacionesRegistro  v-for="(agendamiento,index) in clases" :key="index" :agendamiento="agendamiento" />
      </div>
      <div v-else>
        No tiene clases pendientes.
      </div>

      <b-button class="m-0 p-0" @click="cargarHistorico" size="sm" variant="link" >
        Ver histórico.</b-button>
      <b-button class="btn-block btn-danger" to="/programar">
        Programar
      </b-button>
    </div>
    <Encuesta v-if="encuestaMostrar" :agendamiento="agendamientoEncuesta" @cerrar="actualizarEncuesta" :mostrar="encuestaMostrar"/>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import utils from '@/modules/utils';
  import ProgramacionesRegistro from '@/components/ProgramacionesRegistro';
  import Encuesta from '@/components/Encuesta';

  export default {
    name: 'programaciones',
    components: {
      ProgramacionesRegistro,
      Encuesta
    },
    data () {
      return {
        agendamientoEncuesta:null,
        encuestas:null,
      }
    },
    mounted () {
      //if(this.clases.length < 1 ){
        this.actualizar()
      //}
    },
    methods:{
      ...mapActions({
        fetchClases: 'academico/fetchClases',
        fetchEncuestas: 'academico/fetchEncuestas',
        cancelar: 'academico/cancelar'
      }),
      ...mapMutations({
        
      }),
      textoHora(value){
        return utils.getTextoHora(value)
      },
      edit(){

      },
      actualizar(){
        this.fetchClases()
        this.encuesta();
      },
      cargarHistorico(){
        this.fetchClases({history:true}).then(()=>{})
      },
      encuesta() {
        this.fetchEncuestas().then((result) => {
          this.encuestas = result;
          if (this.encuestas && this.encuestas.length) {
            this.agendamientoEncuesta = this.encuestas[0];
          }
        })
      },
      actualizarEncuesta() {
        this.agendamientoEncuesta=null;
        this.actualizar();
      }
    },
    computed: {
      ...mapState({
        clases: state => state.academico.clases, 
        ultima_actualizacion: state => state.academico.ultima_actualizacion, 
      }),
      ...mapGetters({
        formattedClases: 'academico/formattedClases'
      }),
      lastTime(){
        if(this.ultima_actualizacion){
          return this.ultima_actualizacion.toLocaleString('co-ES')  
        }
        return null
      },
      encuestaMostrar() {
        if (this.agendamientoEncuesta && this.agendamientoEncuesta.clase) {
          if (this.agendamientoEncuesta.showEncuesta) {
            return true;
          }
        }
        return false;
      }
    }
  }
</script>

<style scope>

	.programaciones_wrapper .tabla{
      
	}
</style>